import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import FrontImg from "../../../static/images/st-james-house/big_house_front_cropped.png"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"
import "../../styles/st-james-house.css"


function YC () {
	
	let header = {
        "main": "St. James House",
        "sub": "New Self-Directed Program for Young Adults"
      } 


			//		<h2>
			///			Openings for residency in the year 2020/21 are full. 
				//		</h2>

				//		<p>
				//	To be added to a waitlist in case of a cancellation or for more information, 
				//	please email Bryan and Anna Potts at annaandbryanpotts@gmail.com.
				//	</p>
	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content eri-colors st-james-house-apply">
				<div>
					<Subheader data={header} />
				</div>
				<div className="bh-photo-wrapper">
					<img className="bh-photo" src={FrontImg} />
			
				</div>
				<div className="youth-corps-application">
					<iframe  className="application-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSeKGthUK1gbLMiXFbIAUrTNE3kji9tE3_PzYhszPokpcedYUQ/viewform?embedded=true" width="1000" height="3500" frameborder="0" marginHeight="0" marginwidth="0">Loading…</iframe>
				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default YC 